import React from 'react';
import Header from '../header';
import Footer from '../footer';
import { useLocation } from 'react-router-dom';
import './index.css';

const domObj = {
    '苏州怡合达自动化科技有限公司2023年危险废物污染环境防治信息': (
    <>
      <h2 className="document-h">
        苏州怡合达自动化科技有限公司2023年危险废物污染环境防治信息
      </h2>
      <p className="document-p">
        苏州怡合达自动化科技有限公司成立于2018年01月25日，位于昆山市千灯镇许塘路9号。主要从事自动化设备及配件的设计、研发、生产、加工、销售、安装、维修及改造。公司以“推动智能制造赋能中国制造”为企业愿景，致力打造行业领先的FA工厂自动化零部件一站式供应商。公司以平台化为支撑，以信息和数字化为驱动，充分整合社会资源，链接自动化设备行业上下游资源，以标准设定和产品开发为起点，遵循“产品供给-平台整合-生态驱动”的发展路径，逐渐提高自动化设备中零部件标准化、模块化、组件化的覆盖比例，提升自动化设备供给效率，降低综合成本，最终推动自动化行业的技术进步。
      </p>
      <p className="document-p">
        已按照《危险废物贮存污染控制标准》（GB18597-2023）标准建设一座20平方米的危险废物贮存设施，危险废物主要为废切削液、废液压油，2023年危险废物的产生及处置情况如下表。
      </p>
      <div className="document-div">
        <table className="document-table" border="1px" cellSpacing="0">
          <thead>
            <tr>
              <th>序号</th>
              <th>废物类型</th>
              <th>废物代码</th>
              <th>废物名称</th>
              <th>单位</th>
              <th>产生量</th>
              <th>行政区划</th>
              <th>单位名称</th>
              <th>许可证编号</th>
              <th>经营方式</th>
              <th>实际转移量</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>1</th>
              <th>HW09</th>
              <th>900-005-09</th>
              <th>废液压油</th>
              <th>吨</th>
              <th>5</th>
              <th>常州市,金坛区</th>
              <th>常州市金坛金东环保工程有限公司</th>
              <th>JSCZ0413OOD013-4</th>
              <th>D9</th>
              <th>5</th>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <th>2</th>
              <th>HW09</th>
              <th>900-006-09</th>
              <th>废切削油</th>
              <th>吨</th>
              <th>13</th>
              <th>常州市,金坛区</th>
              <th>常州市金坛金东环保工程有限公司</th>
              <th>JSCZ0413OOD013-4</th>
              <th>D9</th>
              <th>13</th>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  ),
  '淮安伟时科技有限公司轻量化背光显示模组先期项目（第一阶段）建设项目配套的环境保护设施调试信息公示': (
    <>
      <h2 className="document-h">
        淮安伟时科技有限公司轻量化背光显示模组先期项目（第一阶段）建设项目配套的环境保护设施调试信息公示
      </h2>
      <p className="document-p">
        根据《建设项目竣工环境保护验收暂行办法》等的规定，现对“淮安伟时科技有限公司轻量化背光显示模组先期项目（第一阶段）”配套建设的环境保护设施调试的信息向社会公示，使项目建设可能影响区域内的公众对项目建设情况有所了解，并通过公示了解社会公众对本项目的态度和建议，接受社会公众的监督。
      </p>
      <h3>一、调试日期：</h3>
      <p className="document-p">计划调试开始时间：2024年07月11日</p>
      <p className="document-p">预计调试完成时间：2024年07月25日</p>
      <h3>二、征求公众意见的范围：</h3>
      <p className="document-p">
        本建设项目周边环境影响区域内的居民、单位等公众。
      </p>
      <h3>三、公众反馈方式：</h3>
      <p className="document-p">
        公众可将意见或建议来电、来信向淮安伟时科技有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <h3>四、建设单位名称及联系方式：</h3>
      <p className="document-p">建设单位：淮安伟时科技有限公司</p>
      <p className="document-p">联系地址：淮安市淮安区经济开发区赵倚楼路6号昆淮智创园7#、8#厂房</p>
      <p className="document-p">联系电话：15862667819</p>
      <p className="document-p">电子邮箱：zwkhq@ksways.com</p>
      <p className="document-t">淮安伟时科技有限公司</p>
      <p className="document-t">2024-07-10</p>
	</>
  ),
  '淮安伟时科技有限公司轻量化背光显示模组先期项目（第一阶段）建设项目竣工公示': (
    <>
      <h2 className="document-h">
        淮安伟时科技有限公司轻量化背光显示模组先期项目（第一阶段）建设项目竣工公示
      </h2>
      <p className="document-p">
        由淮安伟时科技有限公司建设的“淮安伟时科技有限公司轻量化背光显示模组先期项目（第一阶段）”主体工程、配套工程及环境保护设施已于2024年7月10日完成竣工。根据《建设项目竣工环境保护验收暂行办法》的规定，现向社会各界和市民群众公示，广泛征求各方意见。
      </p>
      <p className="document-p">
        <strong>
          公众可将意见或建议通过来电、来信的方式向淮安伟时科技有限公司
        </strong>
        反映，也可来电、来信咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">淮安市淮安区经济开发区赵倚楼路6号昆淮智创园7#、8#厂房</p>
      <p className="document-p">联系电话：15862667819</p>
      <p className="document-p">电子邮箱：zwkhq@ksways.com</p>
	  <p className="document-t">淮安伟时科技有限公司</p>
	  <p className="document-t">2024-07-9</p>
	</>
  ),
  昆山广兴电子有限公司建设项目配套的环境保护设施调试信息公示: (
    <>
      <h2 className="document-h">建设项目配套的环境保护设施调试信息公示</h2>
      <p className="document-p">
        根据《建设项目竣工环境保护验收暂行办法》等的规定，现对“昆山广兴电子有限公司年产新型电子元器件300万件扩建项目”配套建设的环境保护设施调试的信息向社会公示，使项目建设可能影响区域内的公众对项目建设情况有所了解，并通过公示了解社会公众对本项目的态度和建议，接受社会公众的监督。
      </p>
      <h3>一、调试日期：</h3>
      <p className="document-p">计划调试开始时间：2023年08月05日</p>
      <p className="document-p">预计调试完成时间：2023年10月05日</p>
      <h3>二、征求公众意见的范围：</h3>
      <p className="document-p">
        本建设项目周边环境影响区域内的居民、单位等公众。
      </p>
      <h3>三、公众反馈方式：</h3>
      <p className="document-p">
        公众可将意见或建议来电、来信向昆山广兴电子有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <h3>四、建设单位名称及联系方式：</h3>
      <p className="document-p">建设单位：昆山广兴电子有限公司</p>
      <p className="document-p">联系地址：昆山开发区南浜路168号</p>
      <p className="document-p">联系电话：0512-57700108</p>
      <p className="document-p">电子邮箱：121521@sunon.com</p>
      <p className="document-t">昆山广兴电子有限公司</p>
      <p className="document-t">2024-08-05</p>
    </>
  ),
  昆山广兴电子有限公司建设项目竣工公示: (
    <>
      <h2 className="document-h">建设项目竣工公示</h2>
      <p className="document-p">
        由昆山广兴电子有限公司建设的“昆山广兴电子有限公司年产新型电子元器件300万件扩建项目”主体工程、配套工程及环境保护设施已于2024年4月5日完成竣工。根据《建设项目竣工环境保护验收暂行办法》的规定，现向社会各界和市民群众公示，广泛征求各方意见。
      </p>
      <p className="document-p">
        公众可将意见或建议来电、来信向昆山广兴电子有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">特此公告！</p>
      <p className="document-p">联系地址：昆山开发区南浜路168号</p>
      <p className="document-p">联系电话：0512-57700108</p>
      <p className="document-p">电子邮箱：121521@sunon.com</p>
      <p className="document-t">昆山广兴电子有限公司</p>
      <p className="document-t">2024年-06月-10日</p>
    </>
  ),
  '昆山淀富精密组件有限公司金属零件生产项目（第一阶段）竣工环境保护验收公示': (
    <>
      <h2 className="document-h">
        昆山淀富精密组件有限公司金属零件生产项目（第一阶段）竣工环境保护验收公示
      </h2>
      <p className="document-p">
        根据《建设项目环境保护管理条例》第十七条规定“建设单位应当对配套建设的环境保护设施进行验收，编制验收报告，除按照国家规定需要保密的情形外，建设单位应当依法向社会公开验收报告”，同时根据《建设项目竣工环境保护验收暂行办法》规定“验收报告编制完成后5个工作日内，公开验收报告，公示的期限不得少于20个工作日”。
        <strong>
          昆山淀富精密组件有限公司金属零件生产项目（第一阶段）已通过环境保护设施竣工验收，并取得验收组验收合格的意见，现将该项目环境保护设施验收情况进行公示。
        </strong>
      </p>
      <p className="document-p">
        <strong>
          公众可将意见或建议通过来电、来信的方式向昆山淀富精密组件有限公司
        </strong>
        反映，也可来电、来信咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">公示期：2024年8月13日至2024年9月10日</p>
      <p className="document-p">联系地址：昆山市玉山镇新塘路777号7号房</p>
      <p className="document-p">联系电话：18913268527</p>
      <p className="document-p">电子邮箱：js.zjl@hk-dianfu.com</p>
      <p className="document-p">具体情况如下：</p>
      <div className="document-div">
        <table className="document-table" border="1px" cellSpacing="0">
          <thead>
            <tr>
              <th>建设单位</th>
              <th>项目名称</th>
              <th>建设地点</th>
              <th>验收监测报告编制单位</th>
              <th>公示文件</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>昆山淀富精密组件有限公司</td>
              <td>昆山淀富精密组件有限公司金属零件生产项目（第一阶段）</td>
              <td>昆山市玉山镇新塘路777号7号房</td>
              <td>昆山昆越环境技术有限公司</td>
              <td>详见附件</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="document-t">昆山淀富精密组件有限公司</p>
      <p className="document-t">2024-08-13</p>
      <p className="document-p">
        <div className="margin-10">附件：</div>
        <div className="margin-10">
          <a href="/PDF/12验收报告.pdf" target="_down">
            1 昆山淀富精密组件有限公司-验收监测报告.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/12一般变动变动环境影响分析.pdf" target="_down">
            2 昆山淀富精密组件有限公司 一般变动分析.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/12其他需要说明的事项.pdf" target="_down">
            3 昆山淀富精密组件有限公司 其他需要说明的事项.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/12验收意见.pdf" target="_down">
            4 昆山淀富精密组件有限公司 验收意见.pdf
          </a>
        </div>
      </p>
    </>
  ),
  'DAUNX昆山淀富精密组件有限公司金属零件生产项目（第一阶段）建设项目竣工公示': (
    <>
      <h2 className="document-h">
        DAUNX昆山淀富精密组件有限公司金属零件生产项目（第一阶段）建设项目竣工公示
      </h2>
      <p className="document-p">
        由昆山淀富精密组件有限公司建设的“昆山淀富精密组件有限公司金属零件生产项目（第一阶段）”主体工程、配套工程及环境保护设施已于2024年7月17日完成竣工。根据《建设项目竣工环境保护验收暂行办法》的规定，现向社会各界和市民群众公示，广泛征求各方意见。
      </p>
      <p className="document-p">
        公众可将意见或建议来电、来信向昆山淀富精密组件有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">特此公告！</p>
      <p className="document-p">联系地址：昆山市玉山镇新塘路777号7号房</p>
      <p className="document-p">联系电话：18913268527</p>
      <p className="document-p">电子邮箱：js.zjl@hk-dianfu.com</p>
      <p className="document-t">昆山淀富精密组件有限公司</p>
      <p className="document-t">2024年-07月-17日</p>
    </>
  ),
  '昆山淀富精密组件有限公司金属零件生产项目（第一阶段）建设项目配套的环境保护设施调试信息公示':
    (
      <>
        <h2 className="document-h">
          昆山淀富精密组件有限公司金属零件生产项目（第一阶段）建设项目配套的环境保护设施调试信息公示
        </h2>
        <p className="document-p">
          根据《建设项目竣工环境保护验收暂行办法》等的规定，现对“昆山淀富精密组件有限公司金属零件生产项目（第一阶段）”配套建设的环境保护设施调试的信息向社会公示，使项目建设可能影响区域内的公众对项目建设情况有所了解，并通过公示了解社会公众对本项目的态度和建议，接受社会公众的监督。
        </p>
        <h3>一、调试日期：</h3>
        <p className="document-p">计划调试开始时间：2023年7月17日</p>
        <p className="document-p">预计调试完成时间：2023年8月31日</p>
        <h3>二、征求公众意见的范围：</h3>
        <p className="document-p">
          本建设项目周边环境影响区域内的居民、单位等公众。
        </p>
        <h3>三、公众反馈方式：</h3>
        <p className="document-p">
          公众可将意见或建议来电、来信向苏州鑫腾塑料制品有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
        </p>
        <h3>四、建设单位名称及联系方式：</h3>
        <p className="document-p">建设单位：昆山淀富精密组件有限公司</p>
        <p className="document-p">联系地址：昆山市玉山镇新塘路777号7号房</p>
        <p className="document-p">联系电话：18913268527</p>
        <p className="document-p">电子邮箱：js.zjl@hk-dianfu.com</p>
        <p className="document-t">昆山淀富精密组件有限公司</p>
        <p className="document-t">2024-07-17</p>
      </>
    ),
  '苏州鑫腾塑料制品有限公司塑料制品生产项目（第一阶段）竣工环境保护验收公示': (
    <>
      <h2 className="document-h">
        苏州鑫腾塑料制品有限公司塑料制品生产项目（第一阶段）
        竣工环境保护验收公示
      </h2>
      <p className="document-p">
        根据《建设项目环境保护管理条例》第十七条规定“建设单位应当对配套建设的环境保护设施进行验收，编制验收报告，除按照国家规定需要保密的情形外，建设单位应当依法向社会公开验收报告”，同时根据《建设项目竣工环境保护验收暂行办法》规定“验收报告编制完成后5个工作日内，公开验收报告，公示的期限不得少于20个工作日”。
        <strong>
          苏州鑫腾塑料制品有限公司塑料制品生产项目（第一阶段）已通过环境保护设施竣工验收，并取得验收组验收合格的意见，现将该项目环境保护设施验收情况进行公示。
        </strong>
      </p>
      <p className="document-p">
        <strong>
          公众可将意见或建议通过来电、来信的方式向苏州鑫腾塑料制品有限公司
        </strong>
        反映，也可来电、来信咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">公示期：自公示之日起不少于20个工作日</p>
      <p className="document-p">联系地址：昆山市锦溪镇百胜路288号9号房</p>
      <p className="document-p">联系电话：0512-57237083</p>
      <p className="document-p">电子邮箱：xinteng@wonwo.com.cn</p>
      <p className="document-p">具体情况如下：</p>
      <div className="document-div">
        <table className="document-table" border="1px" cellSpacing="0">
          <thead>
            <tr>
              <th>建设单位</th>
              <th>项目名称</th>
              <th>建设地点</th>
              <th>验收监测报告编制单位</th>
              <th>公示文件</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>苏州鑫腾塑料制品有限公司</td>
              <td>苏州鑫腾塑料制品有限公司塑料制品生产项目（第一阶段）</td>
              <td>昆山市锦溪镇百胜路288号9号房</td>
              <td>昆山昆越环境技术有限公司</td>
              <td>详见附件</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="document-t">苏州鑫腾塑料制品有限公司</p>
      <p className="document-t">2024-07-05</p>
      <p className="document-p">
        <div className="margin-10">附件：</div>
        <div className="margin-10">
          <a href="/PDF/1 鑫腾塑料制品-验收监测报告.pdf" target="_down">
            1 鑫腾塑料制品-验收监测报告.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/2 鑫腾塑料制品 一般变动分析.pdf" target="_down">
            2 鑫腾塑料制品 一般变动分析.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/3 鑫腾塑料制品 其他需要说明的事项.pdf" target="_down">
            3 鑫腾塑料制品 其他需要说明的事项.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/4 鑫腾验收意见.pdf" target="_down">
            4 鑫腾验收意见.pdf
          </a>
        </div>
      </p>
    </>
  ),
  建设项目配套的环境保护设施调试信息公示: (
    <>
      <h2 className="document-h">建设项目配套的环境保护设施调试信息公示</h2>
      <p className="document-p">
        根据《建设项目竣工环境保护验收暂行办法》等的规定，现对“苏州鑫腾塑料制品有限公司塑料制品生产项目”配套建设的环境保护设施调试的信息向社会公示，使项目建设可能影响区域内的公众对项目建设情况有所了解，并通过公示了解社会公众对本项目的态度和建议，接受社会公众的监督。
      </p>
      <h3>一、调试日期：</h3>
      <p className="document-p">计划调试开始时间：2023年4月15日</p>
      <p className="document-p">预计调试完成时间：2023年6月15日</p>
      <h3>二、征求公众意见的范围：</h3>
      <p className="document-p">
        本建设项目周边环境影响区域内的居民、单位等公众。
      </p>
      <h3>三、公众反馈方式：</h3>
      <p className="document-p">
        公众可将意见或建议来电、来信向苏州鑫腾塑料制品有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <h3>四、建设单位名称及联系方式：</h3>
      <p className="document-p">建设单位：苏州鑫腾塑料制品有限公司</p>
      <p className="document-p">联系地址：昆山市锦溪镇百胜路288号9号房</p>
      <p className="document-p">联系电话：0512-57237083</p>
      <p className="document-p">电子邮箱：xinteng@wonwo.com.cn</p>
      <p className="document-t">苏州鑫腾塑料制品有限公司</p>
      <p className="document-t">2024-04-15</p>
    </>
  ),
  建设项目竣工公示: (
    <>
      <h2 className="document-h">
        苏州鑫腾塑料制品有限公司塑料制品生产项目竣工公示
      </h2>
      <p className="document-p">
        由苏州鑫腾塑料制品有限公司建设的“苏州鑫腾塑料制品有限公司塑料制品生产项目”主体工程、配套工程及环境保护设施已于2024年4月5日完成竣工。根据《建设项目竣工环境保护验收暂行办法》的规定，现向社会各界和市民群众公示，广泛征求各方意见。
      </p>
      <p className="document-p">
        公众可将意见或建议来电、来信向苏州鑫腾塑料制品有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">特此公告！</p>
      <p className="document-p">联系地址：昆山市锦溪镇百胜路288号9号房</p>
      <p className="document-p">联系电话：0512-57237083</p>
      <p className="document-p">电子邮箱：xinteng@wonwo.com.cn</p>
      <p className="document-t">苏州鑫腾塑料制品有限公司</p>
      <p className="document-t">2024年-04月-10日</p>
    </>
  ),
  昆山市工业技术研究院小核酸生物技术研究所有限责任公司增加燃气锅炉项目竣工公示:
    (
      <>
        <h2 className="document-h">
          昆山市工业技术研究院小核酸生物技术研究所有限责任公司增加燃气锅炉项目竣工公示
        </h2>
        <p className="document-p">
          由昆山市工业技术研究院小核酸生物技术研究所有限责任公司建设的昆山市工业技术研究院小核酸生物技术研究所有限责任公司增加燃气锅炉项目的主体工程、配套工程及环境保护设施已于2013年05月10日全部完成竣工。根据《建设项目竣工环境保护验收暂行办法》的规定，现向社会各界和市民群众公示，广泛征求各方意见。
        </p>
        <p className="document-p">
          公众可将意见或建议来电、来信向昆山达浩兴包装有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
        </p>
        <p className="document-p">特此公告！</p>
        <p className="document-p">联系地址：昆山市玉山镇元丰路168号</p>
        <p className="document-p">联系电话：15601551992</p>
        <p className="document-p">电子邮箱：1783068738@qq.com</p>
        <p className="document-t">
          昆山市工业技术研究院小核酸生物技术研究所有限责任公司
        </p>
        <p className="document-t">2023年-11月-20日</p>
      </>
    ),
  上海聚龙加油站管理有限公司昆山南港加油站建设项目竣工公示: (
    <>
      <h2 className="document-h">
        上海聚龙加油站管理有限公司昆山南港加油站建设项目竣工公示
      </h2>
      <p className="document-p">
        由上海聚龙加油站管理有限公司昆山南港加油站建设的上海聚龙加油站管理有限公司昆山南港加油站建设项目的主体工程、配套工程及环境保护设施已于2016年12月全部完成竣工。根据《建设项目竣工环境保护验收暂行办法》的规定，现向社会各界和市民群众公示，广泛征求各方意见。
      </p>
      <p className="document-p">
        公众可将意见或建议来电、来信向昆山达浩兴包装有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">特此公告！</p>
      <p className="document-p">联系地址：昆山市张浦镇南港苏虹机场路南侧</p>
      <p className="document-p">联系电话：15601551992</p>
      <p className="document-p">电子邮箱：nang-jyz@concordoid.com</p>
      <p className="document-t">上海聚龙加油站管理有限公司昆山南港加油站</p>
      <p className="document-t">2023年-11月-1日</p>
    </>
  ),
  昆山电子羽电业制品有限公司建设项目竣工公示: (
    <>
      <h2 className="document-h">建设项目竣工公示</h2>
      <p className="document-p">
        由昆山电子羽电业制品有限公司建设的“昆山电子羽电业制品有限公司金属前框、金属背板等生产线技改项目”主体工程、配套工程及环境保护设施已于2023年3月10日完成竣工。根据《建设项目竣工环境保护验收暂行办法》的规定，现向社会各界和市民群众公示，广泛征求各方意见。
      </p>
      <p className="document-p">
        公众可将意见或建议来电、来信向昆山电子羽电业制品有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">特此公告！</p>
      <p className="document-p">联系地址：苏州市昆山市锦溪镇锦东路258号</p>
      <p className="document-p">联系电话：0512-50132588</p>
      <p className="document-p">电子邮箱：bj-jh05@lsjm.cc</p>
      <p className="document-t">昆山电子羽电业制品有限公司</p>
      <p className="document-t">2023-04-10</p>
    </>
  ),
  茂宇昌竣工公示: (
    <>
      <h2 className="document-h">
        昆山茂宇昌精密模具有限公司塑料制品加工项目竣工公示
      </h2>
      <p className="document-p">
        由昆山茂宇昌精密模具有限公司建设的昆山茂宇昌精密模具有
        限公司塑料制品加工项目的主体工程、配套工程及环境保护设施已于 2023 年 03
        月全部完成竣工。根据《建设项目竣工环境保护验收暂行
        办法》的规定，现向社会各界和市民群众公示，广泛征求各方意见。
      </p>
      <p className="document-p">
        公众可将意见或建议来电、来信向昆山茂宇昌精密模具有限公司
        反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">特此公告！</p>
      <p className="document-p">联系地址：昆山市锦溪镇昆开路 28 号 6 号房</p>
      <p className="document-p">联系电话：15601551992</p>
      <p className="document-p">电子邮箱：1783068738@qq.com</p>
      <p className="document-t">昆山茂宇昌精密模具有限公司</p>
      <p className="document-t">2023-04</p>
    </>
  ),
  '立讯精密工业（昆山）有限公司建设项目配套的环境保护设施调试信息公示': (
    <>
      <h2 className="document-h">建设项目配套的环境保护设施调试信息公示</h2>
      <p className="document-p">
        根据《建设项目竣工环境保护验收暂行办法》等的规定，现对“立讯精密工业（昆山）有限公司精密连接器、连接线生产线自动化技术改造项目”配套建设的环境保护设施调试的信息向社会公示，使项目建设可能影响区域内的公众对项目建设情况有所了解，并通过公示了解社会公众对本项目的态度和建议，接受社会公众的监督。
      </p>
      <h3>一、调试日期：</h3>
      <p className="document-p">计划调试开始时间：2023年5月20日</p>
      <p className="document-p">预计调试完成时间：2023年8月20日</p>
      <h3>二、征求公众意见的范围：</h3>
      <p className="document-p">
        本建设项目周边环境影响区域内的居民、单位等公众。
      </p>
      <h3>三、公众反馈方式：</h3>
      <p className="document-p">
        公众可将意见或建议来电、来信向立讯精密工业（昆山）有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <h3>四、建设单位名称及联系方式：</h3>
      <p className="document-p">建设单位：立讯精密工业（昆山）有限公司</p>
      <p className="document-p">联系地址：昆山市锦溪镇锦商路851号</p>
      <p className="document-p">联系电话：0512-82622200</p>
      <p className="document-p">电子邮箱：Cibin.He@luxshare-ict.com</p>
      <p className="document-t">立讯精密工业（昆山）有限公司</p>
      <p className="document-t">2023-05-20</p>
    </>
  ),
  '立讯精密工业（昆山）有限公司建设项目竣工公示': (
    <>
      <h2 className="document-h">建设项目竣工公示</h2>
      <p className="document-p">
        由立讯精密工业（昆山）有限公司建设的“立讯精密工业（昆山）有限公司精密连接器、连接线生产线自动化技术改造项目”主体工程、配套工程及环境保护设施已于2023年5月10日完成竣工。根据《建设项目竣工环境保护验收暂行办法》的规定，现向社会各界和市民群众公示，广泛征求各方意见。
      </p>
      <p className="document-p">
        公众可将意见或建议来电、来信向立讯精密工业（昆山）有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">特此公告！</p>
      <p className="document-p">联系地址：昆山市锦溪镇锦商路851号</p>
      <p className="document-p">联系电话：0512-82622200</p>
      <p className="document-p">电子邮箱：Cibin.He@luxshare-ict.com</p>
      <p className="document-t">立讯精密工业（昆山）有限公司</p>
      <p className="document-t">2023-05-12</p>
    </>
  ),
  '立讯精密工业（昆山）有限公司精密连接器、连接线生产线自动化技术改造项目竣工环境保护验收公示':
    (
      <>
        <h2 className="document-h">
          立讯精密工业（昆山）有限公司精密连接器、连接线生产线自动化技术改造项目竣工环境保护验收公示
        </h2>
        <p className="document-p">
          根据《建设项目环境保护管理条例》第十七条规定“建设单位应当对配套建设的环境保护设施进行验收，编制验收报告，除按照国家规定需要保密的情形外，建设单位应当依法向社会公开验收报告”，同时根据《建设项目竣工环境保护验收暂行办法》规定“验收报告编制完成后5个工作日内，公开验收报告，公示的期限不得少于20个工作日”。
          <strong>
            立讯精密工业（昆山）有限公司精密连接器、连接线生产线自动化技术改造项目已通过环境保护设施竣工验收，并取得验收组验收合格的意见，现将该项目环境保护设施验收情况进行公示。
          </strong>
        </p>
        <p className="document-p">
          <strong>
            公众可将意见或建议通过来电、来信的方式向立讯精密工业（昆山）有限公司
          </strong>
          反映，也可来电、来信咨询公司建设情况。（来信请注明“公示反映”）。
        </p>
        <p className="document-p">公示期：自公示之日起不少于20个工作日</p>
        <p className="document-p">联系地址：昆山市锦溪镇锦商路851号</p>
        <p className="document-p">联系电话：0512-82622200</p>
        <p className="document-p">电子邮箱：Cibin.He@luxshare-ict.com</p>
        <p className="document-p">具体情况如下：</p>
        <div className="document-div">
          <table className="document-table" border="1px" cellSpacing="0">
            <thead>
              <tr>
                <th>建设单位</th>
                <th>项目名称</th>
                <th>建设地点</th>
                <th>验收监测报告编制单位</th>
                <th>公示文件</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>立讯精密工业（昆山）有限公司</td>
                <td>
                  立讯精密工业（昆山）有限公司精密连接器、连接线生产线自动化技术改造项目
                </td>
                <td>昆山市锦溪镇锦商路851号</td>
                <td>昆山昆越环境技术有限公司</td>
                <td>详见附件</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p className="document-t">立讯精密工业（昆山）有限公司</p>
        <p className="document-t">2023-08-02</p>
        <p className="document-p">
          <div className="margin-10">附件：</div>
          <div className="margin-10">
            <a href="/PDF/1 立讯精密技改项目-验收监测报告.pdf" target="_down">
              1 立讯精密技改项目-验收监测报告.pdf
            </a>
          </div>
          <div className="margin-10">
            <a href="/PDF/2 立讯精密技改 一般变动分析.pdf" target="_down">
              2 立讯精密技改 一般变动分析.pdf
            </a>
          </div>
          <div className="margin-10">
            <a href="/PDF/3 立讯精密技改 其他需要说明的事项.pdf" target="_down">
              3 立讯精密技改 其他需要说明的事项.pdf
            </a>
          </div>
          <div className="margin-10">
            <a href="/PDF/4 立讯精密技改验收意见+签到表.pdf" target="_down">
              4 立讯精密技改验收意见+签到表.pdf
            </a>
          </div>
        </p>
      </>
    ),
  '昆山达浩兴包装有限公司纸箱生产项目（第一阶段）竣工公示': (
    <>
      <h2 className="document-h">
        昆山达浩兴包装有限公司纸箱生产项目（第一阶段）竣工公示
      </h2>
      <p className="document-p">
        由昆山达浩兴包装有限公司建设的昆山达浩兴包装有限公司纸箱生产项目（第一阶段）的主体工程、配套工程及环境保护设施已于2023年07月27日全部完成竣工。根据《建设项目竣工环境保护验收暂行办法》的规定，现向社会各界和市民群众公示，广泛征求各方意见。
      </p>
      <p className="document-p">
        公众可将意见或建议来电、来信向昆山达浩兴包装有限公司反映，也可来电咨询公司建设情况。（来信请注明“公示反映”）
      </p>
      <p className="document-p">特此公告！</p>
      <p className="document-p">联系地址：昆山开发区环娄路325 号9 号房一层</p>
      <p className="document-p">联系电话：13706267882</p>
      <p className="document-p">电子邮箱：1783068738@qq.com</p>
      <p className="document-t">昆山达浩兴包装有限公司</p>
      <p className="document-t">2023年07月27日</p>
    </>
  ),
  '昆山电子羽电业制品有限公司金属前框、金属背板等生产线技改项目竣工环境保护验收公示':
    (
      <>
        <h2 className="document-h">
          昆山电子羽电业制品有限公司金属前框、金属背板等生产线技改项目竣工环境保护验收公示
        </h2>
        <p className="document-p">
          根据《建设项目环境保护管理条例》第十七条规定“建设单位应当对配套建设的环境保护设施进行验收，编制验收报告，除按照国家规定需要保密的情形外，建设单位应当依法向社会公开验收报告”，同时根据《建设项目竣工环境保护验收暂行办法》规定“验收报告编制完成后5个工作日内，公开验收报告，公示的期限不得少于20个工作日”。
          <strong>
            昆山电子羽电业制品有限公司金属前框、金属背板等生产线技改项目已通过环境保护设施竣工验收，并取得验收组验收合格的意见，现将该项目环境保护设施验收情况进行公示。
          </strong>
        </p>
        <p className="document-p">
          <strong>
            公众可将意见或建议通过来电、来信的方式向昆山电子羽电业制品有限公司
          </strong>
          反映，也可来电、来信咨询公司建设情况。（来信请注明“公示反映”）。
        </p>
        <p className="document-p">公示期：自公示之日起不少于20个工作日</p>
        <p className="document-p">联系地址：昆山市锦溪镇锦东路258号</p>
        <p className="document-p">联系电话：0512-50132588</p>
        <p className="document-p">电子邮箱：bj-jh05@lsjm.cc</p>
        <p className="document-p">具体情况如下：</p>
        <div className="document-div">
          <table className="document-table" border="1px" cellSpacing="0">
            <thead>
              <tr>
                <th>建设单位</th>
                <th>项目名称</th>
                <th>建设地点</th>
                <th>验收监测报告编制单位</th>
                <th>公示文件</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>昆山电子羽电业制品有限公司</td>
                <td>
                  昆山电子羽电业制品有限公司金属前框、金属背板等生产线技改项目
                </td>
                <td>昆山市锦溪镇锦东路258号</td>
                <td>昆山昆越环境技术有限公司</td>
                <td>详见附件</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p className="document-t">昆山电子羽电业制品有限公司</p>
        <p className="document-t">2023-09-17</p>
        <p className="document-p">
          <div className="margin-10">附件：</div>
          <div className="margin-10">
            <a href="/PDF/1 电子羽技改项目-验收监测报告.pdf" target="_down">
              1 电子羽技改项目-验收监测报告.pdf
            </a>
          </div>
          <div className="margin-10">
            <a href="/PDF/2 电子羽技改 一般变动分析.pdf" target="_down">
              2 电子羽技改 一般变动分析.pdf
            </a>
          </div>
          <div className="margin-10">
            <a href="/PDF/3 电子羽技改 其他需要说明的事项.pdf" target="_down">
              3 电子羽技改 其他需要说明的事项.pdf
            </a>
          </div>
          <div className="margin-10">
            <a href="/PDF/4验收意见.pdf" target="_down">
              4 验收意见.pdf
            </a>
          </div>
        </p>
      </>
    ),
  '昆山达浩兴包装有限公司纸箱生产项目（第一阶段）竣工环境保护验收公示': (
    <>
      <h2 className="document-h">
        昆山达浩兴包装有限公司纸箱生产项目（第一阶段）竣工环境保护验收公示
      </h2>
      <p className="document-p">
        根据《建设项目环境保护管理条例》第十七条规定“建设单位应当对配套建设的环境保护设施进行验收，编制验收报告，除按照国家规定需要保密的情形外，建设单位应当依法向社会公开验收报告”，同时根据《建设项目竣工环境保护验收暂行办法》规定“验收报告编制完成后5个工作日内，公开验收报告，公示的期限不得少于20个工作日”。
        昆山达浩兴包装有限公司纸箱生产项目（第一阶段）已通过环境保护设施竣工验收，并取得验收组验收合格的意见，现将该项目环境保护设施验收情况进行公示。
      </p>
      <p className="document-p">
        公众可将意见或建议通过来电、来信的方式向昆山达浩兴包装有限公司反映，也可来电、来信咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">公示期：2023年9月25日至2023年10月27日</p>
      <p className="document-p">联系地址：昆山开发区环娄路325 号9 号房一层</p>
      <p className="document-p">联系电话：13706267882</p>
      <p className="document-p">电子邮箱：1783068738@qq.com</p>
      <p className="document-p">具体情况如下：</p>
      <div className="document-div">
        <table className="document-table" border="1px" cellSpacing="0">
          <thead>
            <tr>
              <th>建设单位</th>
              <th>项目名称</th>
              <th>建设地点</th>
              <th>验收监测报告编制单位</th>
              <th>公示文件</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>昆山达浩兴包装有限公司</td>
              <td>昆山达浩兴包装有限公司纸箱生产项目（第一阶段）</td>
              <td>昆山开发区环娄路325 号9 号房一层</td>
              <td>昆山昆越环境技术有限公司</td>
              <td>详见附件</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="document-p">
        <div className="margin-10">附件：</div>
        <div className="margin-10">
          <a href="/PDF/8其他需要说明的事项.pdf" target="_down">
            其他需要说明的事项.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/8验收报告.pdf" target="_down">
            验收报告.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/8验收意见.pdf" target="_down">
            验收意见.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/8一般变动变动环境影响分析.pdf" target="_down">
            一般变动变动环境影响分析.pdf
          </a>
        </div>
      </p>
    </>
  ),
  昆山茂宇昌精密模具有限公司塑料制品加工项目竣工环境保护验收公示: (
    <>
      <h2 className="document-h">
        昆山茂宇昌精密模具有限公司塑料制品加工项目竣工环境保护验收公示
      </h2>
      <p className="document-p">
        根据《建设项目环境保护管理条例》第十七条规定“建设单位应当对配套建设的环境保护设施进行验收，编制验收报告，除按照国家规定需要保密的情形外，建设单位应当依法向社会公开验收报告”，同时根据《建设项目竣工环境保护验收暂行办法》规定“验收报告编制完成后5个工作日内，公开验收报告，公示的期限不得少于20个工作日”。
        昆山茂宇昌精密模具有限公司塑料制品加工项目已通过环境保护设施竣工验收，并取得验收组验收合格的意见，现将该项目环境保护设施验收情况进行公示。
      </p>
      <p className="document-p">
        公众可将意见或建议通过来电、来信的方式向昆山达浩兴包装有限公司反映，也可来电、来信咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">公示期：2023年9月12日至2023年10月13日</p>
      <p className="document-p">联系地址：昆山市锦溪镇昆开路28号6号房</p>
      <p className="document-p">联系电话：15601551992</p>
      <p className="document-p">电子邮箱：1783068738@qq.com</p>
      <p className="document-p">具体情况如下：</p>
      <div className="document-div">
        <table className="document-table" border="1px" cellSpacing="0">
          <thead>
            <tr>
              <th>建设单位</th>
              <th>项目名称</th>
              <th>建设地点</th>
              <th>验收监测报告编制单位</th>
              <th>公示文件</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>昆山茂宇昌精密模具有限公司</td>
              <td>昆山茂宇昌精密模具有限公司塑料制品加工项目</td>
              <td>昆山市锦溪镇昆开路28号6号房</td>
              <td>昆山昆越环境技术有限公司</td>
              <td>详见附件</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="document-p">
        <div className="margin-10">附件：</div>
        <div className="margin-10">
          <a href="/PDF/9其他需要说明的事项.pdf" target="_down">
            其他需要说明的事项.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/9验收报告.pdf" target="_down">
            验收报告.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/9验收意见.pdf" target="_down">
            验收意见.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/9一般变动变动环境影响分析.pdf" target="_down">
            一般变动变动环境影响分析.pdf
          </a>
        </div>
      </p>
    </>
  ),
  上海聚龙加油站管理有限公司昆山南港加油站建设项目竣工环境保护验收公示: (
    <>
      <h2 className="document-h">
        上海聚龙加油站管理有限公司昆山南港加油站建设项目竣工环境保护验收公示
      </h2>
      <p className="document-p">
        根据《建设项目环境保护管理条例》第十七条规定“建设单位应当对配套建设的环境保护设施进行验收，编制验收报告，除按照国家规定需要保密的情形外，建设单位应当依法向社会公开验收报告”，同时根据《建设项目竣工环境保护验收暂行办法》规定“验收报告编制完成后5个工作日内，公开验收报告，公示的期限不得少于20个工作日”。上海聚龙加油站管理有限公司昆山南港加油站建设项目已通过环境保护设施竣工验收，并取得验收组验收合格的意见，现将该项目环境保护设施验收情况进行公示。
      </p>
      <p className="document-p">
        公众可将意见或建议通过来电、来信的方式向上海聚龙加油站管理有限公司昆山南港加油站反映，也可来电、来信咨询公司建设情况。（来信请注明“公示反映”）。
      </p>
      <p className="document-p">公示期：2024年1月30日至2024年2月29日</p>
      <p className="document-p">联系地址：昆山市张浦镇南港苏虹机场路南侧</p>
      <p className="document-p">联系电话：15601551992</p>
      <p className="document-p">电子邮箱：nang-jyz@concordoid.com@qq.com</p>
      <p className="document-p">具体情况如下：</p>
      <div className="document-div">
        <table className="document-table" border="1px" cellSpacing="0">
          <thead>
            <tr>
              <th>建设单位</th>
              <th>项目名称</th>
              <th>建设地点</th>
              <th>验收监测报告编制单位</th>
              <th>公示文件</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>上海聚龙加油站管理有限公司昆山南港加油站</td>
              <td>上海聚龙加油站管理有限公司昆山南港加油站建设项目</td>
              <td>昆山市张浦镇南港苏虹机场路南侧</td>
              <td>昆山昆越环境技术有限公司</td>
              <td>详见附件</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="document-p">
        <div className="margin-10">附件：</div>
        <div className="margin-10">
          <a href="/PDF/10其他需要说明的事项.pdf" target="_down">
            其他需要说明的事项.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/10验收报告.pdf" target="_down">
            验收报告.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/10验收意见.pdf" target="_down">
            验收意见.pdf
          </a>
        </div>
        <div className="margin-10">
          <a href="/PDF/10一般变动环境影响分析.pdf" target="_down">
            一般变动变动环境影响分析.pdf
          </a>
        </div>
      </p>
    </>
  ),
  昆山市工业技术研究院小核酸生物技术研究所有限责任公司增加燃气锅炉项目竣工环境保护验收公示:
    (
      <>
        <h2 className="document-h">
          昆山市工业技术研究院小核酸生物技术研究所有限责任公司增加燃气锅炉项目竣工环境保护验收公示
        </h2>
        <p className="document-p">
          根据《建设项目环境保护管理条例》第十七条规定“建设单位应当对配套建设的环境保护设施进行验收，编制验收报告，除按照国家规定需要保密的情形外，建设单位应当依法向社会公开验收报告”，同时根据《建设项目竣工环境保护验收暂行办法》规定“验收报告编制完成后5个工作日内，公开验收报告，公示的期限不得少于20个工作日”。上海聚龙加油站管理有限公司昆山南港加油站建设项目已通过环境保护设施竣工验收，并取得验收组验收合格的意见，现将该项目环境保护设施验收情况进行公示。
        </p>
        <p className="document-p">
          公众可将意见或建议通过来电、来信的方式向昆山市工业技术研究院小核酸生物技术研究所有限责任公司反映，也可来电、来信咨询公司建设情况。（来信请注明“公示反映”）。
        </p>
        <p className="document-p">公示期：2024年1月30日至2024年2月29日</p>
        <p className="document-p">联系地址：昆山市玉山镇元丰路168号</p>
        <p className="document-p">联系电话：15601551992</p>
        <p className="document-p">电子邮箱：1783068738@qq.com</p>
        <p className="document-p">具体情况如下：</p>
        <div className="document-div">
          <table className="document-table" border="1px" cellSpacing="0">
            <thead>
              <tr>
                <th>建设单位</th>
                <th>项目名称</th>
                <th>建设地点</th>
                <th>验收监测报告编制单位</th>
                <th>公示文件</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>昆山市工业技术研究院小核酸生物技术研究所有限责任公司</td>
                <td>
                  昆山市工业技术研究院小核酸生物技术研究所有限责任公司增加燃气锅炉项目
                </td>
                <td>昆山市玉山镇元丰路168号</td>
                <td>昆山昆越环境技术有限公司</td>
                <td>详见附件</td>
              </tr>
            </tbody>
          </table>
        </div>
        <p className="document-p">
          <div className="margin-10">附件：</div>
          <div className="margin-10">
            <a href="/PDF/11其他需要说明的事项.pdf" target="_down">
              其他需要说明的事项.pdf
            </a>
          </div>
          <div className="margin-10">
            <a href="/PDF/11验收报告.pdf" target="_down">
              验收报告.pdf
            </a>
          </div>
          <div className="margin-10">
            <a href="/PDF/11验收意见.pdf" target="_down">
              验收意见.pdf
            </a>
          </div>
          <div className="margin-10">
            <a href="/PDF/11一般变动变动环境影响分析.pdf" target="_down">
              一般变动变动环境影响分析.pdf
            </a>
          </div>
        </p>
      </>
    )
};

const AnnouncementDetail = () => {
  const localData = useLocation();
  return (
    <div>
      <Header />
      <div className="announcement-wrapper">
        <div className="announcement-content">
          <div className="announcement-title">公告</div>
          <div className="announcement-sub">announcement</div>
          <div className="announcement-line"></div>
          <div className="announcement-container">
            {domObj[localData.state.key || '']}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default AnnouncementDetail;
