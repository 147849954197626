import React from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import './index.css';

const Announcement = () => {
  const navigate = useNavigate();

  const list = [
    {
      key: '苏州怡合达自动化科技有限公司2023年危险废物污染环境防治信息',
      index: '25',
      content: (
        <>
          2024年10月12日&nbsp;&nbsp;&nbsp;&nbsp;苏州怡合达自动化科技有限公司2023年危险废物污染环境防治信息 
        </>
      )
    },
    {
      key: '淮安伟时科技有限公司轻量化背光显示模组先期项目（第一阶段）建设项目配套的环境保护设施调试信息公示',
      index: '24',
      content: (
        <>
          2024年09月23日&nbsp;&nbsp;&nbsp;&nbsp;淮安伟时科技有限公司轻量化背光显示模组先期项目（第一阶段）建设项目配套的环境保护设施调试信息公示 
        </>
      )
    },
    {
      key: '淮安伟时科技有限公司轻量化背光显示模组先期项目（第一阶段）建设项目竣工公示',
      index: '23',
      content: (
        <>
          2024年09月23日&nbsp;&nbsp;&nbsp;&nbsp;淮安伟时科技有限公司轻量化背光显示模组先期项目（第一阶段）建设项目竣工公示
        </>
      )
    },
    {
      key: '昆山广兴电子有限公司建设项目配套的环境保护设施调试信息公示',
      index: '22',
      content: (
        <>
          2024年08月05日&nbsp;&nbsp;&nbsp;&nbsp;昆山广兴电子有限公司建设项目配套的环境保护设施调试信息公示
        </>
      )
    },
    {
      key: '昆山广兴电子有限公司建设项目竣工公示',
      index: '21',
      content: (
        <>
          2024年06月10日&nbsp;&nbsp;&nbsp;&nbsp;昆山广兴电子有限公司建设项目竣工公示
        </>
      )
    },
    {
      key: '昆山淀富精密组件有限公司金属零件生产项目（第一阶段）竣工环境保护验收公示',
      index: '20',
      content: (
        <>
          2024年08月13日&nbsp;&nbsp;&nbsp;&nbsp;昆山淀富精密组件有限公司金属零件生产项目（第一阶段）竣工环境保护验收公示
        </>
      )
    },
    {
      key: '昆山淀富精密组件有限公司金属零件生产项目（第一阶段）建设项目配套的环境保护设施调试信息公示',
      index: '19',
      content: (
        <>
          2024年07月17日&nbsp;&nbsp;&nbsp;&nbsp;昆山淀富精密组件有限公司金属零件生产项目（第一阶段）建设项目配套的环境保护设施调试信息公示
        </>
      )
    },
    {
      key: 'DAUNX昆山淀富精密组件有限公司金属零件生产项目（第一阶段）建设项目竣工公示',
      index: '18',
      content: (
        <>
          2024年07月17日&nbsp;&nbsp;&nbsp;&nbsp;DAUNX昆山淀富精密组件有限公司金属零件生产项目（第一阶段）建设项目竣工公示
        </>
      )
    },
    {
      key: '苏州鑫腾塑料制品有限公司塑料制品生产项目（第一阶段）竣工环境保护验收公示',
      index: '17',
      content: (
        <>
          2024年07月05日&nbsp;&nbsp;&nbsp;&nbsp;苏州鑫腾塑料制品有限公司塑料制品生产项目（第一阶段）竣工环境保护验收公示
        </>
      )
    },
    {
      key: '建设项目配套的环境保护设施调试信息公示',
      index: '16',
      content: (
        <>
          2024年04月15日&nbsp;&nbsp;&nbsp;&nbsp;建设项目配套的环境保护设施调试信息公示
        </>
      )
    },
    {
      key: '建设项目竣工公示',
      index: '15',
      content: <>2024年04月10日&nbsp;&nbsp;&nbsp;&nbsp;建设项目竣工公示</>
    },
    {
      key: '昆山市工业技术研究院小核酸生物技术研究所有限责任公司增加燃气锅炉项目竣工环境保护验收公示',
      index: '14',
      content: (
        <>
          2024年01月29日&nbsp;&nbsp;&nbsp;&nbsp;昆山市工业技术研究院小核酸生物技术研究所有限责任公司增加燃气锅炉项目竣工环境保护验收公示
        </>
      )
    },
    {
      key: '上海聚龙加油站管理有限公司昆山南港加油站建设项目竣工环境保护验收公示',
      index: '13',
      content: (
        <>
          2024年01月29日&nbsp;&nbsp;&nbsp;&nbsp;上海聚龙加油站管理有限公司昆山南港加油站建设项目竣工环境保护验收公示
        </>
      )
    },
    {
      key: '昆山市工业技术研究院小核酸生物技术研究所有限责任公司增加燃气锅炉项目竣工公示',
      index: '12',
      content: (
        <>
          2023年11月20日&nbsp;&nbsp;&nbsp;&nbsp;昆山市工业技术研究院小核酸生物技术研究所有限责任公司增加燃气锅炉项目竣工公示
        </>
      )
    },
    {
      key: '上海聚龙加油站管理有限公司昆山南港加油站建设项目竣工公示',
      index: '11',
      content: (
        <>
          2023年11月1日&nbsp;&nbsp;&nbsp;&nbsp;上海聚龙加油站管理有限公司昆山南港加油站建设项目竣工公示
        </>
      )
    },
    {
      key: '昆山茂宇昌精密模具有限公司塑料制品加工项目竣工环境保护验收公示',
      index: '10',
      content: (
        <>
          2023年9月12日&nbsp;&nbsp;&nbsp;&nbsp;昆山茂宇昌精密模具有限公司塑料制品加工项目竣工环境保护验收公示
        </>
      )
    },
    {
      key: '昆山达浩兴包装有限公司纸箱生产项目（第一阶段）竣工环境保护验收公示',
      index: '9',
      content: (
        <>
          2023年9月25日&nbsp;&nbsp;&nbsp;&nbsp;昆山达浩兴包装有限公司纸箱生产项目（第一阶段）竣工环境保护验收公示
        </>
      )
    },
    {
      key: '昆山电子羽电业制品有限公司金属前框、金属背板等生产线技改项目竣工环境保护验收公示',
      index: '8',
      content: (
        <>
          2023年09月17日&nbsp;&nbsp;&nbsp;&nbsp;昆山电子羽电业制品有限公司金属前框、金属背板等生产线技改项目竣工环境保护验收公示
        </>
      )
    },
    {
      key: '昆山电子羽电业制品有限公司建设项目配套的环境保护设施调试信息公示',
      index: '7',
      content: (
        <>
          2023年05月10日&nbsp;&nbsp;&nbsp;&nbsp;昆山电子羽电业制品有限公司建设项目配套的环境保护设施调试信息公示
        </>
      )
    },
    {
      key: '昆山电子羽电业制品有限公司建设项目竣工公示',
      index: '6',
      content: (
        <>
          2023年04月10日&nbsp;&nbsp;&nbsp;&nbsp;昆山电子羽电业制品有限公司建设项目竣工公示
        </>
      )
    },
    {
      key: '昆山达浩兴包装有限公司纸箱生产项目（第一阶段）竣工公示',
      index: '5',
      content: (
        <>
          2023年07月27日&nbsp;&nbsp;&nbsp;&nbsp;昆山达浩兴包装有限公司纸箱生产项目（第一阶段）竣工公示
        </>
      )
    },
    {
      key: '立讯精密工业（昆山）有限公司精密连接器、连接线生产线自动化技术改造项目竣工环境保护验收公示',
      index: '4',
      content: (
        <>
          2023年08月02日&nbsp;&nbsp;&nbsp;&nbsp;立讯精密工业（昆山）有限公司精密连接器、连接线生产线自动化技术改造项目竣工环境保护验收公示
        </>
      )
    },
    {
      key: '立讯精密工业（昆山）有限公司建设项目配套的环境保护设施调试信息公示',
      index: '3',
      content: (
        <>
          2023年05月20日&nbsp;&nbsp;&nbsp;&nbsp;立讯精密工业（昆山）有限公司建设项目配套的环境保护设施调试信息公示
        </>
      )
    },
    {
      key: '立讯精密工业（昆山）有限公司建设项目竣工公示',
      index: '2',
      content: (
        <>
          2023年05月12日&nbsp;&nbsp;&nbsp;&nbsp;立讯精密工业（昆山）有限公司建设项目竣工公示
        </>
      )
    },
    {
      key: '茂宇昌竣工公示',
      index: '1',
      content: (
        <>
          2023年04月&nbsp;&nbsp;&nbsp;&nbsp;昆山茂宇昌精密模具有限公司塑料制品加工项目竣工公示
        </>
      )
    }
  ];
  return (
    <div>
      <Header />
      <div className="announcement-wrapper">
        <div className="announcement-content">
          <div className="announcement-title">公告</div>
          <div className="announcement-sub">announcement</div>
          <div className="announcement-line"></div>
          <div className="announcement-container">
            {list.map((item) => (
              <div
                onClick={() => {
                  navigate(
                    `/announcement-detail/${encodeURIComponent(item.index)}`,
                    { state: { key: item.key } }
                  );
                }}
                key={item.key}
                className="announcement-container-item"
              >
                {item.content}
              </div>
            ))}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Announcement;
